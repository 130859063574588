.nav {
    width: 100%;
    height: 50px;
    background: #fff;
    border-bottom: 1px solid #d9d9d9;
}

.innerBox {
    composes: clearfix from global;
    width: 842px;
    margin: 0 auto;
}

.logo {
    float: left;
    width: 125px;
    height: 50px;
    background: url(https://x0.ifengimg.com/ucms/2020_45/CCA47B3B2DB299B70CB06FB5500DBE12D248BDB0_w125_h50.gif)
        no-repeat 0 0;
    text-indent: -9999999px;
}

.navBtn {
    margin-left: 175px;
}

.navBtn a {
    display: inline-block;
    width: 110px;
    height: 50px;
    line-height: 50px;
    vertical-align: middle;
    text-align: center;
    font-family: 'microsoft yahei';
    font-size: 16px;
    color: #666;
}

.navBtn a:hover,
.navBtn a.cur {
    color: #fff;
    background: #ba0101;
    text-decoration: none;
}

.logout {
    float: right;
}
