.content {
    width: 840px;
    min-height: 680px;
    margin: 0 auto 170px;
    padding: 36px 64px 0;
    background: url(https://x0.ifengimg.com/ucms/2020_09/2DACD750BD0095CD91CB60EACDF318302E334BF8_w401_h399.png)
        no-repeat top left;
}

.i_content {
    width: 100%;
    min-height: 460px;
    border: 1px solid #cdcdcd;
    background: #fff;
}

.security_lv {
    padding: 45px 0 48px 60px;
    margin: 0;
    background-color: #eff4f7;
}

.slv_inner {
    composes: clearfix from global;
    margin-bottom: 13px;
}

.icon {
    display: inline-block;
    background-image: url(https://x0.ifengimg.com/ucms/2020_09/26EB0530876F476FD2E3FC79C434872B920C3E94_w130_h66.gif);
    background-repeat: no-repeat;
    overflow: hidden;
}

.i_icon_lock {
    composes: icon;
    width: 18px;
    height: 20px;
    background-position: -74px 0;
    float: left;
    margin-right: 8px;
    margin-top: 2px;
}

.txt {
    float: left;
    margin-right: 12px;
    font-size: 18px;
}

/* https://x0.ifengimg.com/ucms/2020_09/EA61C2F7F065D28F936DCE2A4930D181CED92492_w15_h96.gif */
.level_box {
    height: 12px;
    width: 532px;
    float: left;
    border-radius: 6px;
    /* background-image: url(../images/security/i_level_bg.gif); */
    background-repeat: no-repeat;
    overflow: hidden;
    zoom: 1;
    position: relative;
    /* background-position: 0 0; */
    background-color: #e2e8eb;
    margin-top: 7px;
    margin-right: 10px;
}

.level_bar_low {
    position: absolute;
    top: 0;
    left: 0;
    background-color: #e20000;
    height: 12px;
    border-radius: 6px;
}

.level_bar_mid {
    position: absolute;
    top: 0;
    left: 0;
    background-color: #fca534;
    height: 12px;
    border-radius: 6px;
}

.level_bar_high {
    position: absolute;
    top: 0;
    left: 0;
    background-color: #56b635;
    height: 12px;
    border-radius: 6px;
}

.level_txt {
    float: left;
    color: #e20000;
    font-size: 18px;
}

.tips {
    font-size: 16px;
}

.item {
    padding: 0;
    margin: 0 20px;
    border-bottom: 1px dashed #cdcdcd;
}

.item_inner {
    composes: clearfix from global;
    padding: 45px 0 48px 40px;
    overflow: hidden;
    zoom: 1;
}

.rightTxt {
    float: right;
    color: #0076c2;
    text-decoration: none;
    font-size: 14px;
}

.left_box {
    float: left;
    margin-right: 70px;
}

.left_box span {
    margin-right: 5px;
    height: 20px;
    line-height: 20px;
    vertical-align: top;
    font-size: 16px;
}

.phoneIcon {
    composes: icon;
    width: 13px;
    background-position: 0 0;
}

.fz16 {
    font-size: 16px;
}

.itemTip {
    padding-top: 8px;
    font-size: 14px;
    color: #999;
}

.mailIcon {
    composes: icon;
    width: 20px;
    background-position: -13px 0;
}

.pwdIcon {
    composes: icon;
    width: 18px;
    background-position: -56px 0;
}

.check {
    composes: icon;
    width: 19px;
    background-position: -92px 0;
    margin-right: 5px;
    height: 20px;
    line-height: 20px;
    vertical-align: top;
}
