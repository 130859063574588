.footer {
    padding-bottom: 20px;
}

.p1 {
    margin-bottom: 6px;
    line-height: 16px;
    font-size: 14px;
    text-align: center;
    color: #666666;
}

.p2 {
    margin-bottom: 6px;
    line-height: 16px;
    font-size: 14px;
    text-align: center;
    color: #999999;
}
